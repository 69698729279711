import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link, useParams } from 'react-router-dom'
import './scss/screen.scss'

const lists = {
  'black-and-white': {
    name: 'Black & White',
    theme: 'black',
    images: {
      'smoking-man': {
        title: 'Smoking Man',
        year: 2019,
        location: 'Hannover, Germany',
        orientation: 'portrait'
      },
      'georgii-et-jacobi': {
        title: 'Georgii et Jacobi',
        year: 2019,
        location: 'Hannover, Germany',
        orientation: 'portrait'
      },
      'autumn-arms': {
        title: 'Autumn Arms',
        year: 2019,
        location: 'Hannover, Germany'
      }
    }
  },
  landscapes: {
    name: 'Landscapes',
    theme: 'white',
    images: {
      'ile-d-oleron': {
        title: "Île d'Oleron",
        year: 2016,
        location: "Île d'Oleron, France"
      },
      'austrian-morning': {
        title: 'Austrian Morning',
        year: 2018,
        location: 'Austria'
      },
      'maschsee-steg': {
        title: 'Maschsee Steg',
        year: 2015,
        location: 'Hannover, Germany'
      },
      haelleviksstrand: {
        title: 'Hälleviksstrand',
        year: 2019,
        location: 'Hälleviksstrand, Sweden'
      }
    }
  }
}

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" children={<App view="home" />} />
        <Route exact path="/:listId" children={<App view="listing" />} />
        <Route exact path="/:listId/:imageId" children={<App view="image" />} />
      </Switch>
    </Router>
  )
}

const App = props => {
  const { listId } = useParams()
  const theme = listId && lists[listId].theme ? lists[listId].theme : 'white'
  return (
    <div className={`App App--${theme} App--${props.view}`}>
      <header className="App__header">
        <Link to="/" className="App__logo">
          Marcel Otten
        </Link>
        <nav>
          <ul className="App__nav">
            {Object.keys(lists).map(category => (
              <li key={category}>
                <Link
                  className={`App__nav__item ${category === listId ? 'App__nav__item--active' : ''}`}
                  to={`/${category}`}>
                  {lists[category].name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <Listing />
      <Lightbox />
    </div>
  )
}

function Listing() {
  const { listId } = useParams()
  const images = listId && lists[listId].images ? lists[listId].images : {}
  return (
    <ul className="Listing">
      {Object.keys(images).map(image => (
        <li
          key={image}
          className={`Listing__item Listing__item--${
            images[image].orientation ? images[image].orientation : 'landscape'
          }`}>
          <Link to={`/${listId}/${image}`}>
            <img src={`/images/thumbs/${image}.jpg`} alt="" />
          </Link>
        </li>
      ))}
    </ul>
  )
}

function Lightbox() {
  const { listId, imageId } = useParams()
  const info = imageId ? lists[listId].images[imageId] : false
  return (
    <div className={`Lightbox ${imageId ? 'Lightbox--visible' : ''}`}>
      <img src={`/images/full/${imageId}.jpg`} alt="" />
      <Link to={`/${listId}`} type="button" className="Lightbox__close">
        Close
      </Link>
      {info && (
        <div className="Lightbox__info">
          <p className="Lightbox__info__title">{info.title}</p>
          <p className="Lightbox__info__meta">
            <span className="Lightbox__info__meta__year">{info.year}</span> {info.location}
          </p>
        </div>
      )}
    </div>
  )
}
